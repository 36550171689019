<template>
  <div class="page-job">
    <div class="g-wrap">
      <el-breadcrumb class="g-breadcrumb" separator="/">
        <el-breadcrumb-item :to="`/cooperation/home-enterprise/post?id=123`"
          >岗位信息
        </el-breadcrumb-item>
        <el-breadcrumb-item>{{ model.JobName }}</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="g-content job-content">
        <div class="head">
          <div class="left">
            <h3>{{ model.JobName }}</h3>
            <p>
              <strong>{{ job.Salary }}</strong>
              <span>{{ model.Address || "--" }}</span>
              <span>{{ job.Education }}</span>
              <span>{{ job.Experience }}</span>
              <span>招{{ model.RecruitCount }}人</span>
            </p>
          </div>
          <div class="right">
            <div class="btn">申请岗位</div>
            <div class="date">{{ model.CreatedTime }} 发布</div>
          </div>
        </div>
        <div class="body">
          <div class="left">
            <column-header :data="{ name: '员工福利' }"></column-header>
            <div class="tags">
              <span v-for="(item, i) in job.Benefits" :key="i">{{ item }}</span>
            </div>
            <column-header :data="{ name: '职位描述' }"></column-header>
            <div class="content" v-html="job.JobDescription"></div>
            <!-- <column-header :data="{ name: '任职要求' }"></column-header>
            <div class="content" v-html="job.JobRequirements"></div> -->
            <column-header :data="{ name: '联系方式' }"></column-header>
            <div class="content" v-html="model.Contact"></div>
          </div>
          <div class="right">
            <column-header :data="{ name: '公司信息' }"></column-header>
            <el-image
              style="width: 80px; height: 80px;"
              src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg"
            >
            </el-image>
            <h3 class="title">{{ enterprise.Name }}</h3>
            <p>
              <i class="ico-industry">标</i>
              {{ enterprise.Industry }}
            </p>
            <p>
              <i class="ico-type">标</i>
              {{ enterprise.Nature }}
            </p>
            <p>
              <i class="ico-size">标</i>
              {{ enterprise.Scale }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ColumnHeader from "@/components/ColumnHeader2";
export default {
  components: {
    ColumnHeader
  },
  data() {
    return {
      model: {
        JobName: "前端开发",
        Address: 123,
        RecruitCount: 123,
        CreatedTime: "2021-10-10",
        Enterprise: 123,
        Contact: `深圳市龙华新区1970科技小组8栋307`
      },
      job: {
        Salary: "10-15k",
        Education: "大专及以上",
        Experience: "1-2年",
        Benefits: ["五险一金"],
        JobDescription: `岗位职责：
1. 负责区块链项目的技术选型、架构设计、核心代码开发；
2. 负责区块链底层平台的搭建和优化， 支撑行业应用产品；
3. 负责区块链创新技术预研，包过数据隐私、共识算法、分布式数据结构和算法、跨链技术等；
4. 区块链经济模型和治理机制探索。

任职要求：
1. 具有扎实的计算机操作系统、算法、数据结构、分布式系统、网络通信、文件存储相关理论知识；
2. 具备扎实的编程能力，熟练使用c/c++、go、python、java等 编程语言；
3. 具备密码学知识，熟悉加密算法、数字签名、零知识证明、同态加密、差分隐私等技术；
4. 深入理解共识机制，如：POW、POS、PBFT、RAFT、Tendermint；
5. 熟悉常见的公有链和联盟链技术，如：BTC、 Ethereum、EOS、Fabric、Fisco Bcos；
6. 热爱区块链行业，对区块链有自己的认识。`,
        JobRequirements: 123,
        EnterpriseId: 123
      },
      tags: [],
      enterprise: {
        Name: "智邻科技有限公司",
        LogoLong: "",
        Industry: "计算机软件",
        Nature: "民营企业",
        Scale: "100人以上"
      }
    };
  },
  created() {
    this.init();
  },
  methods: {
    loadData() {},
    init() {
      this.loadData();
    }
  }
};
</script>

<style lang="scss" scoped>
.page-job {
  background: #f6f6f9;
  width: 100%;
  padding: 24px 0 40px 0;
  .g-wrap {
    position: relative;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
  }
  .job-content {
    margin-top: 8px;
    background: #fff;
    .head,
    .body {
      display: flex;
      justify-content: space-between;
    }
    .head {
      padding: 40px 64px;
      border-bottom: 1px solid #eee;
      .left {
        span {
          position: relative;
          padding-left: 10px;
          margin-left: 10px;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #cccccc;
          line-height: 24px;
          &::before {
            width: 1px;
            height: 14px;
            line-height: 24px;
            background-color: #ccc;
            position: absolute;
            left: 0;
            top: 4px;
            content: " ";
          }
        }
        strong {
          color: #ff6010;
          font-weight: 500;
          font-size: 20px;
        }
        h3 {
          margin: 0;
          font-size: 24px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #333333;
          line-height: 33px;
        }
        p {
          margin: 10px 0 0 0;
        }
      }
      .right {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .date {
          color: #ccc;
          text-align: center;
          padding-top: 10px;
        }
        h3 {
          font-size: 16px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #333333;
          line-height: 24px;
        }
      }
      .btn {
        padding: 5px 16px;
        background: #598DF3;
        border-radius: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 22px;
        &:hover {
          cursor: pointer;
          box-shadow: 0px 4px 12px 0px rgba(#598DF3, 0.3);
        }
      }
    }
    .body {
      margin: 0 64px 40px 64px;
      .right {
        margin-left: 48px;
        display: flex;
        flex-direction: column;
        .el-image {
          margin: 0 auto;
        }
        .title {
          margin: 13px 0 10px;
          padding: 0;
          font-size: 16px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #333333;
          line-height: 24px;
        }
        p {
          margin: 0;
          margin-bottom: 8px;
          height: 24px;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          line-height: 24px;
          i {
            margin-right: 10px;
          }
        }
      }
      .left {
        flex: 1;
      }
    }
    .tags {
      color: #999;
      font-size: 12px;
      span {
        background: #fcfcfc;
        border-radius: 4px;
        border: 1px solid #dddddd;
        padding: 2px 4px;
        margin-right: 8px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 14px;
      }
    }
    .content {
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 32px;
    }
  }
}
</style>
